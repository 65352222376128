var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"documentNotes"},[_c('div',{staticClass:"documentNotes__title border-bottom d-flex align-items-center"},[_vm._m(0),_c('div',[_c('h4',{staticClass:"mb-0 text-dark"},[_vm._v(_vm._s(_vm.data.name || "")+" Workflow")]),(_vm.data.workflow_status == 'COMPLETED')?_c('p',{staticClass:"notes-text text-muted"},[_vm._v(" Workflow Completed ")]):(_vm.data.workflow_status == 'ARCHIVED')?_c('p',{staticClass:"notes-text text-muted"},[_vm._v(" Workflow Archived ")]):(_vm.data.workflow_status != 'COMPLETED')?_c('p',{staticClass:"notes-text text-muted"},[_vm._v(" Workflow is In-progress ")]):_vm._e()])]),_c('div',{staticClass:"documentNotes_body"},[_c('el-scrollbar',{attrs:{"wrap-style":"height: calc(100vh - 358px);"}},[_c('div',{staticClass:"tracking-status"},[(_vm.allDocuments && _vm.allDocuments.length)?_c('div',{staticClass:"document-status"},_vm._l((_vm.allDocuments),function(doc,index){return _c('div',{key:index,staticClass:"box d-flex mb-1",style:(_vm.getActiveStyle(doc)),on:{"click":function($event){return _vm.gotoDocument(doc)}}},[_c('div',{staticClass:"status-icon me-2"},[_c('i',{class:doc.icon})]),(_vm.getIsMobile)?_c('div',{staticClass:"mobileStyle"},[_c('h6',{staticClass:"mb-0 fs-10"},[_vm._v(" "+_vm._s(_vm._f("truncate")(doc.document_id.title,16, "..."))),_c('br'),(
                    doc.document_id.document_tracking_status &&
                    doc.document_id.document_tracking_status.description
                  )?_c('p',{class:doc.mainStatus ? 'float-right' : '',staticStyle:{"font-size":"8px"},style:(_vm.getActiveStyle(doc))},[_vm._v(" "+_vm._s(doc.document_id.document_tracking_status.description)+" ")]):_vm._e()]),(doc.mainStatus)?_c('p',{class:doc.textStyle},[_vm._v(" "+_vm._s(doc.document_status)+" ")]):_vm._e()]):_c('div',{staticClass:"head w-100"},[_c('h4',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm._f("truncate")(doc.document_id.title,24, "..."))),_c('br'),(
                    doc.document_id.document_tracking_status &&
                    doc.document_id.document_tracking_status.description
                  )?_c('p',{class:doc.mainStatus ? 'float-right' : '',staticStyle:{"font-weight":"normal","font-size":"10px"},style:(_vm.getActiveStyle(doc))},[_vm._v(" "+_vm._s(doc.document_id.document_tracking_status.description)+" ")]):_vm._e()]),(doc.mainStatus)?_c('p',{class:doc.textStyle},[_vm._v(" "+_vm._s(doc.document_status)+" ")]):_vm._e()])])}),0):_vm._e()])])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pr-1"},[_c('i',{staticClass:"el-icon-document-copy fs-4"})])
}]

export { render, staticRenderFns }