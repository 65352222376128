<template>
  <div class="documentNotes">
    <div class="documentNotes__title border-bottom d-flex align-items-center">
      <div class="pr-1">
        <i class="el-icon-document-copy fs-4"></i>
      </div>
      <div>
        <h4 class="mb-0 text-dark">{{ data.name || "" }} Workflow</h4>
        <p
          class="notes-text text-muted"
          v-if="data.workflow_status == 'COMPLETED'"
        >
          Workflow Completed
        </p>
        <p
          class="notes-text text-muted"
          v-else-if="data.workflow_status == 'ARCHIVED'"
        >
          Workflow Archived
        </p>
        <p
          class="notes-text text-muted"
          v-else-if="data.workflow_status != 'COMPLETED'"
        >
          Workflow is In-progress
        </p>
      </div>
    </div>
    <div class="documentNotes_body">
      <el-scrollbar wrap-style="height: calc(100vh - 358px);">
        <div class="tracking-status">
          <div
            class="document-status"
            v-if="allDocuments && allDocuments.length"
          >
            <div
              class="box d-flex mb-1"
              :style="getActiveStyle(doc)"
              v-for="(doc, index) in allDocuments"
              :key="index"
              @click="gotoDocument(doc)"
            >
              <div class="status-icon me-2">
                <i :class="doc.icon"></i>
              </div>
              <div v-if="getIsMobile" class="mobileStyle">
                <h6 class="mb-0 fs-10">
                  {{ doc.document_id.title | truncate(16, "...") }}<br />
                  <p
                    :class="doc.mainStatus ? 'float-right' : ''"
                    style="font-size: 8px"
                    :style="getActiveStyle(doc)"
                    v-if="
                      doc.document_id.document_tracking_status &&
                      doc.document_id.document_tracking_status.description
                    "
                  >
                    {{ doc.document_id.document_tracking_status.description }}
                  </p>
                </h6>
                <p :class="doc.textStyle" v-if="doc.mainStatus">
                  {{ doc.document_status }}
                </p>
              </div>
              <div v-else class="head w-100">
                <h4 class="mb-0">
                  {{ doc.document_id.title | truncate(24, "...") }}<br />
                  <p
                    :class="doc.mainStatus ? 'float-right' : ''"
                    style="font-weight: normal; font-size: 10px"
                    :style="getActiveStyle(doc)"
                    v-if="
                      doc.document_id.document_tracking_status &&
                      doc.document_id.document_tracking_status.description
                    "
                  >
                    {{ doc.document_id.document_tracking_status.description }}
                  </p>
                </h4>
                <p :class="doc.textStyle" v-if="doc.mainStatus">
                  {{ doc.document_status }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "EmpDoc-DocumentWorkflowStatus",
  props: ["data", "currentAuthUserEmail", "workflow_data_id"],
  computed: {
  ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
  },
  async mounted() {
    await this.getDocuments();
  },
  data() {
    return {
      allDocuments: [],
    };
  },
  methods: {
    getActiveStyle(doc) {
      let styles = "";
      let currentActiveDoc = this.$route.params.employee_document_id;
      if (currentActiveDoc == doc.document_id._id) {
        styles = "color: #409EFF;";
      } else if (this.checkIsAllow(doc.document_id.document_users)) {
        styles = "color: #C0C4CC; cursor: not-allowed;";
      } else {
        styles = "color: #4C4B4E;";
      }
      return styles;
    },
    checkIsAllow(users) {
      if (users && users.length) {
        let currentUser = users.find(
          (e) =>
            e &&
            this.currentAuthUserEmail &&
            e.email &&
            e.email.toLowerCase() == this.currentAuthUserEmail.toLowerCase()
        );
        if (currentUser) {
          return false;
        }
      }
      return true;
    },
    gotoDocument(data) {
      let currentActiveDoc = this.$route.params.employee_document_id;
      if (
        data &&
        data.document_id.document_users &&
        data.document_id._id &&
        !this.checkIsAllow(data.document_id.document_users) &&
        currentActiveDoc != data.document_id._id
      ) {
        this.$router.push({
          name: "employee-documents-custom-document",
          params: {
            employee_document_id: data.document_id._id,
          },
          query: {
            workflow_id: this.workflow_data_id,
          },
        });
        setTimeout(() => {
          this.refreshDocument();
        }, 1000);
      }
    },
    refreshDocument() {
      this.$router.go();
    },
    getDocuments() {
      if (this.data && this.data.documents && this.data.documents.length) {
        this.data.documents.forEach((doc) => {
          if (doc.document_id.document_status == "COMPLETED") {
            this.$set(doc, "icon", "el-icon-check success");
            this.$set(doc, "textStyle", "text-success");
            this.$set(doc, "mainStatus", true);
          } else if (
            doc.document_id.document_status == "SENT" ||
            doc.document_id.document_status == "APPROVED"
          ) {
            this.$set(doc, "icon", "el-icon-refresh in-progress");
            this.$set(doc, "textStyle", "text-fail");
            this.$set(doc, "mainStatus", false);
          } else if (doc.document_id.document_status == "VOIDED") {
            this.$set(doc, "icon", "el-icon-circle-close not-sent");
            this.$set(doc, "textStyle", "text-danger");
            this.$set(doc, "mainStatus", true);
          } else if (doc.document_id.document_status == "DECLINED") {
            this.$set(doc, "icon", "el-icon-circle-close success");
            this.$set(doc, "textStyle", "text-danger");
            this.$set(doc, "mainStatus", true);
          } else {
            this.$set(doc, "icon", "el-icon-circle-close success");
            this.$set(doc, "textStyle", "text-danger");
            this.$set(doc, "mainStatus", true);
          }
          this.allDocuments.push(doc);
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.documentNotes {
  display: flex;
  flex-direction: column;
  .documentNotes__title {
    padding: 10px 15px;
  }
  .documentNotes_body {
    .notes {
      display: grid;
      grid-template-columns: 45px 1fr;
      padding: 12px 10px 12px;
      overflow-y: hidden !important;
      &:last-child {
        padding-bottom: 12px;
      }
      .notes-avatar {
        .avatar-text {
          height: 35px;
          width: 35px;
          font-weight: 600;
          font-size: 1.1em;
          line-height: 35px;
          text-align: center;
          padding: 0;
          border-radius: 30px;
          background-color: #ffffff;
        }
      }
      .notes-content {
        position: relative;
        h5 {
          font-size: 1.05em;
          color: #444444;
          font-weight: 600;
          line-height: 18px;
          margin-bottom: 0.15em;
          .postTime {
            font-size: 12px;
            font-weight: 400;
            color: #aaaaaa;
          }
        }
        .notes-text {
          font-size: 1em;
          line-height: 1.5;
          word-break: break-word;
          span {
            color: #2e4eff;
          }
        }
      }
      .color-1 {
        border: 1.5px solid #f2994a;
        color: #f2994a;
      }
      .color-2 {
        border: 1.5px solid #2d9cdb;
        color: #2d9cdb;
      }

      .color-3 {
        border: 1.5px solid #bb6bd9;
        color: #bb6bd9;
      }
      .color-4 {
        border: 1.5px solid #27ae60;
        color: #27ae60;
      }
      .color-5 {
        border: 1.5px solid #1f22ec;
        color: #1f22ec;
      }
      .color-6 {
        border: 1.5px solid #1d6648;
        color: #1d6648;
      }
      .color-7 {
        border: 1.5px solid #e20215;
        color: #e20215;
      }
      .color-8 {
        border: 1.5px solid #ff008c;
        color: #ff008c;
      }
    }
    max-height: calc(100vh) !important;
  }
  .documentNotes_entry {
  }
  .tracking-status {
    padding: 1em;
    background: #ffffff;
  }
}
  .mobileStyle{
    scroll-behavior: auto;
    overflow: scroll;
  }
.document-status {
  .box {
    cursor: pointer;
    position: relative;
    &:last-child {
      &:after {
        display: none;
      }
    }
    &::after {
      content: "";
      position: absolute;
      top: 10px;
      left: 9px;
      width: 1px;
      height: 50px;
      border-left: 2px dashed #bbbbbb;
    }
    .active {
      background: red;
    }
  }

  .status-icon {
    position: relative;
    z-index: 10;
    i {
      color: #ffffff;
      height: 20px;
      width: 20px;
      text-align: center;
      line-height: 20px;
      border-radius: 10px;
    }
    .success {
      background-color: #48968a;
    }
    .in-progress {
      background-color: #f15178;
    }
    .not-sent {
      background-color: #121212;
    }
  }
}
</style>